<template>
<!-- 报名缴费 -->
    <div class="RegPayment">
        <loading v-show="isHttpData" style="margin-top: 2.1rem;"></loading>
        <div class="RegPayment-box" v-if="!isHttpData && payData.length != 0">
            <div class="pay-main">
                <div class="pay-title flex-between">
                    <div class="pay-name">缴费金额</div>
                    <div class="pay-number">￥{{payData.payment_amount?payData.payment_amount:0 }}</div>
                </div>
                <div class="pay-introduce">
                    <div class="introduce-content flex-between">
                        <div class="content-left">
                            <p v-html="payData.describe"></p>
                        </div>
                        <div class="content-right">
                            <img src="../assets/pay/anquan.svg" alt="">
                            <i class="el-icon-arrow-right" v-if="showArrow" @click="unfold"></i>
                        </div>
                    </div>
                    <div class="pay-mode">
                        <van-collapse v-model="activeName" accordion >
                          <van-collapse-item name="1" :is-link="false">
                              <template #title>
                                <div class="bankcard flex-start">
                                    <img src="../assets/pay/yinhang.svg" alt="">
                                    <div class="flex-between">
                                        <span class="margin-left">银行卡</span>
                                        <van-radio-group v-model="activeName">
                                          <van-radio name="1" checked-color="#00ccb0"></van-radio>
                                        </van-radio-group>
                                        <div class="position-box"></div>
                                    </div>
                                </div>
                              </template>
                              <div class="bankcard-content">
                                  <div class="bankcard-box">
                                      <div class="flex-start">
                                          <span class="bank_number">{{ payData.bank_card.bank_card_number}}</span>
                                          <span class="duplication duplication-number" :data-clipboard-text="payData.bank_card.bank_card_number" @click="copyNumber">复制</span>
                                      </div>
                                      <div class="flex-start">
                                          <span>收款人姓名：</span>
                                          <span class="user_name">{{ payData.bank_card.name }}</span>
                                          <span class="duplication duplication-name" :data-clipboard-text="payData.bank_card.name" @click="copyName">复制</span>
                                      </div>
                                      <div>开户行：{{ payData.bank_card.account_bank }}</div>
                                  </div>
                                  <p style="font-size: .13rem; margin-top: .12rem;">需要支付金额  <span style="color: #FB5C56;">￥{{payData.payment_amount}}</span></p>
                                  <p>转账时注意卡号和收款人姓名</p>
                                  <p> 完成付款后请点击下方“我已完成支付” <span style="color: #FB5C56;"> 上传支付凭证</span></p>
                              </div>
                          </van-collapse-item>
                          <van-collapse-item name="2" :is-link="false">
                              <template #title>
                                <div class="alipay flex-start">
                                    <img src="../assets/pay/zhifubao.svg" alt="">
                                    <div  class="flex-between">
                                        <span class="margin-left">支付宝</span>
                                        <van-radio-group v-model="activeName">
                                          <van-radio name="2" checked-color="#00ccb0"></van-radio>
                                        </van-radio-group>
                                        <div class="position-box"></div>
                                    </div>
                                </div>
                              </template>
                              <div class="bankcard-content">
                                  <div class="zfb-img">
                                      <img class="zfb-img-one" v-lazy="payData.alipay_url" @click="imagePreview(payData.alipay_url)" :src="payData.alipay_url" alt="">
                                      <img class="download" @click="saveImg(true)" src="../assets/pay/download.svg" alt="">
                                  </div>
                                  <p style="font-size: .13rem; margin-top: .12rem;">需要支付金额  <span style="color: #FB5C56;">￥{{payData.payment_amount}}</span></p>
                                  <p>转账时注意卡号和收款人姓名</p>
                                  <p> 完成付款后请点击下方“我已完成支付” <span style="color: #FB5C56;"> 上传支付凭证</span></p>
                              </div>
                          </van-collapse-item>
                              <van-collapse-item name="3" :is-link="false">
                                  <template #title>
                                    <div class="WeChat flex-start">
                                        <img src="../assets/pay/weixin.svg" alt="">
                                        <div  class="flex-between">
                                            <span class="margin-left">微信</span>
                                            <van-radio-group v-model="activeName">
                                              <van-radio name="3" checked-color="#00ccb0"></van-radio>
                                            </van-radio-group>
                                            <div class="position-box"></div>
                                        </div>
                                    </div>
                                  </template>
                                  <div class="bankcard-content">
                                      <div class="zfb-img">
                                          <img id="img-down" v-lazy="payData.wechat_url" @click="imagePreview(payData.wechat_url)" :src="payData.wechat_url" alt="">
                                          <img class="download" @click="saveImg(false)" src="../assets/pay/download.svg" alt="">
                                      </div>
                                      <p style="font-size: .13rem; margin-top: .12rem;">需要支付金额  <span style="color: #FB5C56;">￥{{payData.payment_amount}}</span></p>
                                      <p>转账时注意卡号和收款人姓名</p>
                                      <p> 完成付款后请点击下方“我已完成支付” <span style="color: #FB5C56;"> 上传支付凭证</span></p>
                                  </div>
                              </van-collapse-item>
                        </van-collapse>
                    </div>
                </div>
            </div>
            <div class="have-paid">
                <div class="have-paid-box flex-between" @click="completePay = !completePay">
                    <span>我已完成支付</span>
                    <van-checkbox v-model="completePay" checked-color="#00ccb0"></van-checkbox>
                </div>
                <!-- <Support style="margin-top: .2rem;"></Support> -->
            </div>
            <div class="have-paid-btn">
                <van-button :disabled="!completePay" :color="!completePay?'#ccc':''" @click="goPayPath">我已完成支付</van-button>
            </div>
        </div>
        <Support v-show="!isHttpData && payData.length == 0" style="position:absolute;bottom: .1rem;left: 50%; transform: translate(-50%, 0);"></Support>
        <img v-show="!isHttpData && payData.length == 0" class="position-img" src="../assets/null.svg" alt="">
        <sideBar></sideBar>
    </div>
</template>

<script>
import Clipboard from "clipboard";
import Support from '@/components/support';
import sideBar from '@/components/sidebar';
import Loading from "@/components/loading/loading";
import { ImagePreview } from "vant"; // 引入Vant图片预览组件
export default {
    data() {
        return {
            activeName: '1',
            orderInfo: '',
            name: '',
            completePay: false,
            isHttpData: true,
            payData: {
                bank_card: {
                    bank_card_number: ''
                }
            },
            dataURL: require('../assets/pay/zfb.png'),
            showArrow: false
        }
    },
    components:{
        sideBar,
        Support,
        Loading
    },
    async created(){
        // this.unfold()
        // const data = await this.$store.dispatch('get_user_certificate', {
        //     site_id: this.$route.query.site_id
        // })
        // if(data.data.code == 200){
        //     if(data.data.data.payment_voucher){
        //         let site_id = this.$route.query.site_id
        //         let module_id = this.$route.query.module_id
        //         this.$router.push({
        //             "path": "/invoice",
        //             "query": {
        //                 "site_id": site_id,
        //                 "module_id": module_id
        //             }
        //         })
        //     }else{
                let obj = {
                    site_id: this.$route.query.site_id,
                    module_id: this.$route.query.module_id
                }
                const res = await this.$store.dispatch('show_payment_page', obj)
                if(res.data.code == 200){
                    document.title = res.data.data.module_title ? res.data.data.module_title : (sessionStorage.getItem('wei-title') || '轻微站');
                    this.payData = res.data.data.module_content;
                    this.isHttpData = false
                    if(this.payData.length != 0){
                        this.$nextTick().then(() => {
                            var minHeight = document.querySelector('.content-left p').clientHeight
                            if(minHeight > 80){
                                this.showArrow = true
                            }else{
                                this.showArrow = false
                            }
                        })
                    }
                }
        //     }
        // }
    },
    methods: {
        imagePreview(url){
            let img = [];
            img.push(url)
            ImagePreview({
                images:img, //需要预览的图片 URL 数组
                showIndex:true, //是否显示页码
                loop:false, //是否开启循环播放
                closeable: true,  // 显示关闭按钮
            });
        },
        unfold(){
            this.$nextTick().then(() => {
                var minHeight = document.querySelector('.content-left').clientHeight
                var pHeight = document.querySelector('.content-left p').clientHeight
                var arrow = document.querySelector('.content-right .el-icon-arrow-right')
                if(pHeight > 80){
                    if(minHeight>80){
                        document.querySelector('.content-left').style.height = '80px'
                        arrow.classList.remove('is-rotate')
                    }else{
                        document.querySelector('.content-left').style.height = pHeight + 10 + 'px'
                        arrow.classList.add('is-rotate')
                    }
                }
            })
        },
        //下载图片
        saveImg(val){
            if(val){
                if(!this.payData.alipay_url){
                    this.$toast('下载失败')
                }else{
                    var canvas = document.createElement('canvas');
                    var img = document.createElement('img');
                    img.onload = function(e) {
                        canvas.width = img.width;
                        canvas.height = img.height;
                        var context = canvas.getContext('2d');
                        context.drawImage(img, 0, 0, img.width, img.height);
                        // window.navigator.msSaveBlob(canvas.msToBlob(),'image.jpg');
                        // saveAs(imageDataUrl, '附件');
                        canvas.getContext('2d').drawImage(img, 0, 0, img.width, img.height);
                        canvas.toBlob((blob)=>{
                            let link = document.createElement('a');
                            link.href = window.URL.createObjectURL(blob);
                            link.download = 'Alipay'; 
                            link.click();  
                        }, "image/jpeg");
                    }
                    img.setAttribute("crossOrigin",'Anonymous');
                    img.src = this.payData.alipay_url;
                }
            }else{
                if(!this.payData.wechat_url){
                    this.$toast('下载失败')
                }else{
                    var canvas = document.createElement('canvas');
                    var img = document.createElement('img');
                    img.onload = function(e) {
                        canvas.width = img.width;
                        canvas.height = img.height;
                        var context = canvas.getContext('2d');
                        context.drawImage(img, 0, 0, img.width, img.height);
                        // window.navigator.msSaveBlob(canvas.msToBlob(),'image.jpg');
                        // saveAs(imageDataUrl, '附件');
                        canvas.getContext('2d').drawImage(img, 0, 0, img.width, img.height);
                        canvas.toBlob((blob)=>{
                            let link = document.createElement('a');
                            link.href = window.URL.createObjectURL(blob);
                            link.download = 'WeChat'; 
                            link.click();  
                        }, "image/jpeg");
                    }
                    img.setAttribute("crossOrigin",'Anonymous');
                    img.src = this.payData.wechat_url;
                }
            }
        },
        goPayPath() {
            let site_id = this.$route.query.site_id
            let module_id = this.$route.query.module_id
            this.$router.push({
                "path": "/invoice",
                "query": {
                    "site_id": site_id,
                    "module_id": module_id
                  
                }
            })
        },
        async copyNumber() {
            var clipboard = new Clipboard(".duplication-number");
            clipboard.on("success", e => {
              // 释放内存
              this.$toast('复制成功');
              clipboard.destroy();
            });
            clipboard.on("error", e => {
              // 不支持复制
              this.$toast('复制失败');
              // 释放内存
              clipboard.destroy();
            });
        },
        async copyName() {
            var clipboard = new Clipboard(".duplication-name");
            clipboard.on("success", e => {
              // 释放内存
              this.$toast('复制成功');
              clipboard.destroy();
            });
            clipboard.on("error", e => {
              // 不支持复制
              this.$toast('复制失败');
              // 释放内存
              clipboard.destroy();
            });
        },
    }
}
</script>

<style lang="less" scoped>
    @themecolor: #00ccb0;
    .is-rotate{
        -ms-transform: rotate(90deg); /* IE 9 */
        transform: rotate(90deg);
    }
    .tranrotate{
        transform:rotate(45deg);
    }
    *{
        box-sizing: border-box;
    }
    .flex-start{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .flex-between{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .RegPayment{
        // height: calc(100vh-.44rem);
        overflow-y: visible;
        background-color: #F3F2F7;
        // padding: 12px 15px;
        // box-sizing: border-box;
        .RegPayment-box{
            height: 92%;
            overflow-y: scroll;
            padding: .12rem .15rem .3rem;
        }
        .pay-main{
            background-color: #fff;
            border-radius: .1rem;
            overflow: hidden;
            width: 100%;
            .pay-title{
                height: .54rem;
                font-size: .16rem;
                padding: 0 .1rem;
            }
            .pay-number{
                color: #FB5A54;
            }
            .pay-introduce{
                padding: .12rem .1rem;
                box-sizing: border-box;
                background-image: linear-gradient(to right, rgba(0,245,211,0.15) , rgba(112,214,254,0.15));
                .introduce-content{
                    align-items: flex-start;
                    .content-left{
                        flex: 1;
                        padding: .04rem .06rem;
                        box-sizing: border-box;
                        min-height: .6rem;
                        height: .8rem;
                        overflow: hidden;
                        transition: height 1s;
                        -moz-transition: height 1s;
                        -webkit-transition: height 1s;
                        -o-transition: height 1s;
                        p{
                            font-size: .13rem;
                            line-height: .24rem;
                        }
                    }
                    .content-right{
                        width: .45rem;
                        height: .70rem;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: center;
                        img{
                            display: block;
                            width: .34rem;
                            height: .38rem;
                        }
                        .el-icon-arrow-right{
                            font-size: .24rem;
                            margin-top: .04rem;
                            z-index: 20;
                            transform: 1s;
                        }
                    }
                }
                .pay-mode{
                    width: 100%;
                    border-radius: .1rem;
                    background-color: #fff;
                    overflow: hidden;
                    font-size: .14rem;
                    .bankcard{
                        img{
                            display: block;
                            width: .3rem;
                            height: .2rem;
                        }
                        div{
                            width: 100%;
                            // position: relative;
                        }
                    }
                    .alipay{
                        img{
                            margin-left: .02rem;
                            display: block;
                            width: .24rem;
                            height: .24rem;
                        }
                        div{
                            width: 100%;
                            // position: relative;
                        }
                    }
                    .WeChat{
                        img{
                            margin-left: .03rem;
                            display: block;
                            width: .25rem;
                        }
                         div{
                            width: 100%;
                            // position: relative;
                            
                        }
                    }
                    .bankcard-content{
                        background-color: #F7F7F7;
                        font-size: .12rem;
                        padding: .15rem .16rem;
                        box-sizing: border-box;
                        border-radius: .1rem;
                        .zfb-img{
                            position: relative;
                            img{
                                display: block;
                                width: 1rem;
                                height: 1.52rem;
                                margin: 0 auto;
                                object-fit: scale-down;
                            }
                            .download{
                                width: .22rem;
                                height: .22rem;
                                display: block;
                                position: absolute;
                                right: 0;
                                top: 0;
                            }
                        }
                        .bankcard-box{
                            background: linear-gradient(to right, rgba(248,127,106,0.8) , rgba(251,88,83,0.8));
                            width: 100%;
                            border-radius: .05rem;
                            padding: .21rem 0 .18rem .21rem;
                            color: #fff;
                            box-sizing: border-box;
                            .bank_number{
                                font-size: .14rem;
                            }
                            div{
                                height: .18rem;
                            }
                            div:nth-child(2){
                                margin: .03rem 0 .16rem;
                            }
                            span{
                                height: .18rem;
                                line-height: .18rem;
                            }
                            .duplication{
                                margin-left: .18rem;
                                display: block;
                                width: .37rem;
                                height: .18rem;
                                text-align: center;
                                color: #FB5A54;
                                background-color: #fff;
                                border-radius: .09rem;
                            }
                        }
                        p{
                            color: #666;
                            text-align: center;
                            font-size: .11rem;
                            line-height: .24rem;
                            
                        }
                    }
                    /deep/ .van-radio-group{
                        width: .2rem!important;
                        height: .2rem!important;
                        /deep/ i.van-icon.van-icon-success{
                            background-color: @themecolor!important;
                            border-color: @themecolor!important;
                        }
                        /deep/ .van-radio__icon--checked{ 
                            /deep/ i.van-icon{
                                background-color: #00ccb0!important;
                                border-color: @themecolor!important;
                            }
                        }
                    }
                    .margin-left{
                        margin-left: .1rem;
                        box-sizing: border-box;
                    }
                }
            }
        }
        .have-paid{
            height: .54rem;
            font-size: .16rem;
            margin-top: .2rem;
            .have-paid-box{
                padding: 0 .25rem;
                width: 100%;
                height: .54rem;
                border-radius: .1rem;
                background-color: #fff;
            }
        }
        .have-paid-btn{
            position: fixed;
            bottom: 0;
            left: 0;
            height: .65rem!important;
            width: 100%;
            background-color: #fff;
            .van-button{
                position: fixed;
                left: .15rem;
                bottom: .05rem;
                border: .01rem solid #00ccb0;
                color: #fff;
                background-color: #00ccb0;
                height: .54rem;
                width: calc(100% - .3rem);
                border-radius: .27rem;
                // box-sizing: border-box;
            }
        }
    }
    .position-box{
        display: block;
        position: absolute;
        width: 0.6rem;
        height: .4rem!important;
        right: 0;
        top: 0;
        z-index: 99;
    }
    .position-img{
        position: fixed;
        width: 3rem;
        height: 3rem;
        left: 50%;
        top: 45%;
        transform: translate(-50%, -50%);
    }
</style> 